import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import headerKoko from '../../../images/new-landing/header-koko.svg';

import { Text } from '../../../components';

import './style.scss';

const ProgramicHeader = () => {
  return (
    <Row
      className="section-header section-header-small programic-header"
      noGutters
    >
<Col
  className="section-header-title d-flex flex-column align-items-center justify-content-center text-center mx-auto"
  md={8}
  sm={12}
>
        <img src={headerKoko} alt="Koko" />
        <Text as="h3">
          <Localize id="programic.SectionHeader.TitlePrefix" />
        </Text>
        <Text as="h1" bold>
          <Localize id="programic.SectionHeader.Title" />
        </Text>
        <Text as="h2">
          <Localize id="programic.SectionHeader.Subtitle" />
        </Text>
        <Text as="h3" className="mb-1">
          <Localize id="programic.SectionHeader.Description.First" />
        </Text>
        <Text as="h3">
          <Localize id="programic.SectionHeader.Description.Second" />
        </Text>
      </Col>
    </Row>
  );
};

export default memo(ProgramicHeader);
