import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, Card, Icon, ButtonNew, PricingItems } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';
import MaterialPackage from './MaterialPackage';

import './style.scss';

const ICON_CONFIG_FIRST = {
  1: 'like',
  2: 'devices',
};

const ICON_CONFIG_SECOND = {
  3: 'home2',
  4: 'puzzle',
};

const WHY_CHOOSE_ITEMS = [
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item1',
  },
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item2',
  },
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item3',
  },
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item4',
  },
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item5',
  },
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item6',
  },
  {
    available: true,
    descriptionId: 'programic.Details.Section1.Item7',
  },
];

const ProgramicDetails = () => {
  return (
    <div className="programic-details">
      <Row className="mb-5 programic-material-package-container justify-content-center">
        <Col md={3}>
          <MaterialPackage
            titleKey="programic.Details.Materials.Title1"
            descriptionKey="programic.Details.Materials.Description1"
            iconName="home2"
          />
        </Col>
        <Col md={3}>
          <MaterialPackage
            titleKey="programic.Details.Materials.Title2"
            descriptionKey="programic.Details.Materials.Description2"
            iconName="checklistTime2"
          />
        </Col>
        <Col md={3}>
          <MaterialPackage
            titleKey="programic.Details.Materials.Title3"
            descriptionKey="programic.Details.Materials.Description3"
            iconName="camera"
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(ProgramicDetails);
