import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text } from '../../../components';
import { FAQnew } from '../..';

import './style.scss';

const answers = [
  {
    question: <Localize id="programic.FAQ.Section1.Question1" />,
    answer: (
      <Localize
        id="programic.FAQ.Section1.Answer1"
        values={{
          newline: () => <br />,
        }}
      />
    ),
  },
  {
    question: <Localize id="programic.FAQ.Section1.Question2" />,
    answer: (
      <Localize
        id="programic.FAQ.Section1.Answer2"
        values={{
          newline: () => <br />,
        }}
      />
    ),
  },
  {
    question: <Localize id="programic.FAQ.Section1.Question3" />,
    answer: (
      <Localize
        id="programic.FAQ.Section1.Answer3"
        values={{
          newline: () => <br />,
        }}
      />
    ),
  },
];

const ProgramicFAQ = () => {
  return (
    <div>
      <Row
        className="programic-faq-container pt-5 pb-5 justify-content-center align-items-center"
        noGutters
      >
        <Col className="programic-faq-text" xs={10}>
          <Text as="h2" center bold>
            <Localize id="programic.FAQ.Title" />
          </Text>
          <FAQnew
            answers={answers}
            openIconName="plus2"
            closeIconName="minus2"
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(ProgramicFAQ);
