import React from 'react';

export default () => (
  <>
    <div className="container h4">
      <p>
        <h1>Datum stupanja na snagu: 28.1.2025.</h1>
      </p>
      <p>
        <h1>1. Općenito</h1>
      </p>
      <p>
        <b>
          Kokolingo logopedska aplikacija na jednostavan način pomaže
          roditeljima kod poboljšanja izgovora njihove djece. Sadrži mnoštvo
          stručnih materijala za rad na izgovoru glasova te olakšava ponavljanja
          i praćenje djetetovog napretka bilo kada i bilo gdje.
        </b>
      </p>
      <p>
        <b>Kokolingo mobilna aplikacija</b> (u daljem tekstu &quot;mi&quot;,
        &quot;naš&quot; ili &quot;nas&quot;) poštuje vašu privatnost i obvezuje
        se da će je štititi kroz ova Pravila privatnosti. Ova Pravila o
        privatnosti objašnjavaju kako prikupljamo, koristimo, otkrivamo i
        štitimo vaše podatke kada koristite našu mobilnu aplikaciju
        (&quot;Aplikacija&quot;). Molimo pažljivo pročitajte ova Pravila
        privatnosti. Ako se ne slažete s uvjetima ovih Pravila privatnosti,
        nemojte pristupati niti koristiti Aplikaciju. Mobilna aplikacija
        namijenjena je roditeljima za preuzimanje i rad sa svojom djecom. Kao
        dodatno sigurnosno rješenje, aplikacija također ima ugrađena
        &quot;roditeljska vrata&quot;, što znači da su dijelovi aplikacije sa
        sustavima plaćanja ili poveznice izvan aplikacije ograničeni, odnosno
        predviđeni isključivo za korištenje od strane roditelja. Informacije
        koje se koriste u aplikaciji ne zahtijevaju identifikatore djece. Naša
        mobilna aplikacija ne sadrži nikakve reklame. Također, ne uključuje
        nikakav sadržaj koji generiraju korisnici ili značajke društvene
        interakcije. Naša web stranica koristi uobičajene tehnologije praćenja
        za podršku inicijativama oglašavanja i analitike. Surađujemo s
        partnerima trećih strana koji koriste alate poput kolačića za praćenje
        posjeta našim platformama usmjerenim za korištenje od strane odraslih
        osoba. Ovi se podaci koriste za promidžbu naših proizvoda i usluga
        odraslima, uključujući i vanjske web stranice. Kao odrasla osoba, imate
        mogućnost upravljanja ovim praćenjem putem postavki kolačića. Politika
        privatnosti naših web stranica dostupna je na{' '}
        <a href="http://www.kokolingo.hr/izjava-o-privatnosti" hrefLang="hr">
          Politika privatnosti
        </a>
        . Zadržavamo pravo ažuriranja ovih Pravila privatnosti u bilo kojem
        trenutku, u skladu s primjenjivim zakonima. Nastojimo održavati Pravila
        privatnosti aktualnim. Ako dođe do značajnih promjena, obavijestit ćemo
        vas o tome. Osim toga, ako se naša praksa privatnosti promijeni na način
        koji zahtijeva verificirani pristanak roditelja, osigurat ćemo da takav
        pristanak dobijemo.
      </p>
      <p>
        <h1>2. Podaci koje prikupljamo</h1>
      </p>
      <p>
        <h2>Osobni podaci</h2>
      </p>
      <p>
        Vrste podataka koji se prikupljaju i koriste razlikuje se ovisno o
        opciji usluge koju odaberete. Na to također utječe način na koji ste
        postavili svoj račun, vašu lokaciju i koristite li usluge trećih strana
        za prijavu. To uključuje ili može uključivati vaše:
      </p>
      <ol>
        <li>
          Podatke o računu: Vaše ime, adresu e-pošte, lozinku, broj telefona,
          datum rođenja, naziv grada
        </li>
        <li>
          Podatke o profilu: Sve dodatne informacije koje odlučite dati u svom
          profilu.
        </li>
      </ol>
      <p>
        <h2>Podaci o korištenju</h2>
      </p>
      <p>
        Osobni podaci prikupljeni i obrađeni dok pristupate ili koristite
        aplikaciju Kokolingo uključuju ili mogu uključivati nekoliko vrsta
        informacija, kao što je navedeno u sljedećim odjeljcima:
      </p>
      <ol>
        <li>
          <b>Informacije o uređaju</b>: jedinstveni identifikator vašeg mobilnog
          uređaja, operativni sustav i verzija aplikacije.
        </li>
        <li>
          <b>Podaci o digitalnim zapisima</b>: IP adresa, vrijeme i datum
          pristupa, pregledane stranice, približna geolokacija i korištenje
          značajki aplikacije.
        </li>
        <li>
          <b>Podaci partnera za provjeru autentičnosti</b>: Ako se registrirate
          ili prijavite u aplikaciju Kokolingo putem druge platforme, ta
          platforma može podijeliti vaše podatke s nama. Ovi se podaci koriste
          za postavljanje vašeg računa.
        </li>
        <li>
          <b>Analitički podaci</b>: prikupljeni putem Google Analyticsa ili
          sličnog rješenja, uključujući korisničke interakcije, trajanje sesije
          i druge skupne podatke za poboljšanje funkcionalnosti aplikacije.
        </li>
      </ol>
      <p>
        <h2>Kolačići i slične tehnologije</h2>
      </p>
      <p>
        Možemo koristiti{' '}
        <b>kolačiće, oznake piksela, web beacons ili slične tehnologije</b> za
        prikupljanje podataka i poboljšanje vašeg iskustva putem naših usluga,
        na temelju vaše aktivnosti.
      </p>
      <p>
        <h1>3. Kako koristimo vaše podatke</h1>
      </p>
      <p>
        <h2>Podatke koje prikupljamo koristimo u sljedeće svrhe:</h2>
      </p>
      <ul>
        <li>Izradu i ažuriranje vašeg računa.</li>
        <li>
          Za pružanje, održavanje i poboljšanje značajki i funkcionalnosti
          aplikacije.
        </li>
        <ul>
          <li>
            Za personalizaciju vašeg iskustva na temelju vaših preferencija (na
            primjer, možemo vam dati personalizirane preporuke ili prijedloge na
            temelju vaših prethodnih narudžbi).
          </li>
          <li>
            Za komunikaciju s vama u vezi ažuriranja, promocija ili tehničke
            podrške.
          </li>
          <li>
            Za analizu obrazaca korištenja i optimiziranje naših usluga pomoću
            Google Analytics.
          </li>
        </ul>
        <li>
          Kako bismo osigurali sigurnost i spriječili prijevaru, možemo
          koristiti podatke za zaštitu usluga i korisnika.
        </li>
        <li>
          Za poštivanje zakonskih obveza i provođenje naših uvjeta i odredbi.
        </li>
        <li>
          Za prilagođavanje marketinških komunikacija i oglasa o našim
          proizvodima i uslugama.
        </li>
        <ul>
          <li>
            Na primjer, možemo slati push obavijesti s preporukom naših
            proizvoda.
          </li>
          <li>
            Za roditelje, možemo koristiti vaše osobne podatke za dijeljenje
            promotivnih materijala o našim uslugama putem e-pošte, kao što su
            newsletteri. Djecu ne ciljamo, ne prikupljamo njihove podatke, niti
            im se reklamiramo.
          </li>
          <li>
            Koristimo kolačiće ili slične tehnologije uz vaš pristanak, kako
            zahtijevaju primjenjivi zakoni. Međutim, ne koristimo kolačiće ili
            slične tehnologije unutar aplikacije u reklamne svrhe.
          </li>
          <li>
            U nekim slučajevima možemo surađivati s oglasnim mrežama trećih
            strana, izdavačima i platformama za oglašavanje kako bismo
            promovirali svoje oglase određenim segmentima odrasle publike na
            drugim web-mjestima ili u aplikacijama.
          </li>
          <li>
            Određeni osobni podaci (kao što su IP adrese) prikupljeni na ovim
            platformama trećih strana mogu biti unešeni u našim sustavima kada
            odrasli korisnik izvrši određene radnje unutar naše aplikacije (npr.
            instalacija aplikacije ili slične aktivnosti). Ovi se podaci koriste
            za procjenu uspjeha naših marketinških aktivnosti.
          </li>
        </ul>
      </ul>
      <p>
        <h2>Pravna osnova za obradu osobnih podataka</h2>
      </p>
      <p>
        Vaše podatke obrađujemo na zakonit, transparentan i pošten način. Ovisno
        o vrsti osobnih podataka i specifičnom kontekstu, kada djelujemo kao
        voditelj obrade podataka za pojedince u regijama kao što su EGP,
        Švicarska i UK, oslanjamo se na sljedeće pravne osnove koje su
        primjenjive u vašoj nadležnosti:
      </p>
      <ul>
        <li>
          Dostatnost za naš ugovor: Kada sklopimo ugovor izravno s vama,
          obrađujemo vaše osobne podatke za pripremu, izvršenje i upravljanje
          ugovorom. To uključuje pružanje naših proizvoda, usluga i značajki.
        </li>
        <li>
          Na temelju vašeg pristanka: Oslanjamo se na vaš izričiti pristanak za
          korištenje kolačića za interakciju s partnerima za oglašavanje i
          analitiku, omogućavanje prilagođenih oglasa i analize korištenja web
          stranice.
        </li>
        <li>
          Za poštivanje zakonskih obveza: Vaše osobne podatke obrađujemo kako
          bismo ispunili zakonske zahtjeve prema primjenjivim zakonima,
          propisima, smjernicama ili kodeksima prakse EGP-a. To uključuje
          odgovaranje na zahtjeve javnih, vladinih, sudskih ili regulatornih
          tijela i rješavanje lažnih, štetnih ili nezakonitih aktivnosti.
        </li>
        <li>
          Za legitimne interese: Osobne podatke možemo obrađivati na temelju
          naših ili legitimnih interesa drugih, pod uvjetom da oni ne
          nadjačavaju vaša prava i slobode. Ti interesi uključuju:
        </li>
        <ul>
          <li>Pružanje proizvoda, usluga i podrške.</li>
          <li>
            Razvoj, testiranje i poboljšanje naših proizvoda i usluga te
            rješavanje tehničkih problema.
          </li>
          <li>
            Osiguravanje provjere autentičnosti računa, integriteta, sigurnosti,
            uključujući otkrivanje i sprječavanje zlonamjernog ponašanja,
            kršenja uvjeta, nesigurnih iskustava i sigurnosnih prijetnji.
          </li>
          <li>
            Slanje marketinških komunikacija, oglasa i promocija povezanih s
            našim proizvodima i uslugama.
          </li>
          <li>
            Usklađenost sa zakonima, propisima ili smjernicama izvan EGP-a,
            rješavanje zahtjeva regulatornih tijela izvan EGP-a, ispunjavanje
            korporativnih i društvenih odgovornosti, zaštitu prava i imovine
            (naših i kupčevih), rješavanje sporova i provođenje sporazuma.
          </li>
        </ul>
      </ul>
      <p>
        <h2>Privatnost djece</h2>
      </p>
      <p>
        Naša aplikacija i usluge osmišljene su kako bi roditeljima i skrbnicima
        dale potpunu kontrolu. Za izradu računa zahtijevamo od odrasle osobe da
        se registrira, osiguravajući minimalno ili nikakvo prikupljanje podataka
        o djetetu. Konkretno, možemo zatražiti da se djetetovo ime, nadimak ili
        drugo izmišljeno ime djeteta po izboru roditelja pojavi u aplikaciji
        tijekom obrazovnih aktivnosti. Davanje podataka (u kontekstu imena
        djeteta) nije obavezno, a ime ili drugi detalji nisu vidljivi drugim
        korisnicima aplikacije. Nakon što roditelj kreira djetetov račun,
        nastojimo prikupiti samo one informacije koje su potrebne za
        sudjelovanje djeteta u obrazovnim aktivnostima koje se nude putem naših
        usluga. Roditelji ili skrbnici mogu pregledati, izmijeniti ili izbrisati
        osobne podatke kontaktirajući nas na info@kokolingo.hr. Kako bismo
        osigurali privatnost i sigurnost, možemo tražiti korake provjere ili
        dodatne pojedinosti za potvrdu vašeg identiteta prije bilo kakvih
        promjena ili pružanja informacija. Ako otkrijemo da smo nenamjerno
        prikupili osobne podatke od djeteta (prema definiranoj minimalnoj dobi
        za prikupljanje podataka u danoj regiji), odmah ćemo ih izbrisati osim
        ako smo zakonski obvezni da ih zadržimo.
      </p>
      <p>
        <h1>4. Kako dijelimo vaše podatke</h1>
      </p>
      <p>
        Vaše osobne podatke ne prodajemo niti iznajmljujemo trećim stranama.
        Međutim, možemo podijeliti vaše podatke u sljedećim okolnostima:
      </p>
      <ul>
        <li>
          <b>Pružatelji usluga</b>: Možemo dijeliti podatke s pružateljima
          usluga kao što je Google Analytics koji pomažu u analizi i poboljšanju
          aplikacije. Google Analytics prikuplja podatke kao što su interakcije
          korisnika, duljine sesija i navigacijski putovi. Ove nam informacije
          pomažu poboljšati naše usluge i korisničko iskustvo.
        </li>
        <li>
          <b>Partneri za oglašavanje</b>: Ako ste roditelj, možemo dijeliti vaše
          osobne podatke s partnerima za oglašavanje (trećim stranama), do
          razine dopuštene primjenjivim zakonom, kako bismo promovirali naše
          usluge na vanjskim web-mjestima ili aplikacijama i kako bismo
          procijenili uspjeh naših marketinških kampanja. Osim podataka
          prikupljenih unutar naše aplikacije, oglašivački partneri (treće
          strane) mogu izravno koristiti tehnologije i alate za praćenje na
          našim uslugama kako bi prikupili pojedinosti o vašim aktivnostima i
          uređaju (npr. IP adresa, identifikatori kolačića, posjećene stranice,
          okvirna lokacija i vrijeme pristupa). Imajte na umu da ne prikupljamo
          niti dijelimo podatke od djece. Ako ikada nenamjerno prikupimo takve
          informacije, poduzet ćemo potrebne korake da odmah riješimo i
          ispravimo situaciju.
        </li>
        <li>
          <b>Pravne obveze</b>: Ako to zahtijeva zakon ili kao odgovor na
          valjane pravne zahtjeve javnih tijela. Na primjer, radi usklađivanja
          sa zakonom, uredbom, pravnim postupkom ili zahtjevom vlade.
        </li>
        <li>
          <b>Poslovni prijenosi</b>: U slučaju spajanja, akvizicije ili prodaje
          imovine tvrtke, vaši se podaci mogu prenijeti kao dio tog procesa.
        </li>
        <li>
          <b>Dobavljači</b>: Za pružanje naših usluga možemo surađivati s nizom
          dobavljača, uključujući tvrtke koje nude IT usluge, poslovne alate ili
          IT infrastrukturu. To može uključivati pružatelje usluga hostinga,
          CRM-ove, pružatelje usluga e-pošte, tvrtke za obradu plaćanja, usluge
          korisničke podrške i druge.
        </li>
      </ul>
      <p>
        <h1>5. Zadržavanje podataka</h1>
      </p>
      <p>
        Zadržavamo vaše podatke samo onoliko dugo koliko je potrebno za
        ispunjavanje svrha navedenih u ovim Pravilima privatnosti ili u skladu
        sa zakonskim obvezama. Kada podaci više nisu potrebni, bit će sigurno
        izbrisani ili anonimizirani. Poduzimamo odgovarajuće tehničke i
        organizacijske mjere kako bismo zaštitili vaše podatke od neovlaštenog
        pristupa, otkrivanja ili uništenja. Međutim, nijedna sigurnosna mjera
        nije nepogrešiva i ne možemo jamčiti apsolutnu sigurnost. Čimbenici koje
        uzimamo u obzir pri određivanju razdoblja zadržavanja podataka
        uključuju:
      </p>
      <ul>
        <li>
          <b>Trajanje našeg odnosa</b>: Duljina vremena u kojem održavamo
          aktivan odnos s vama i pružamo Kokolingo proizvode i usluge (npr. sve
          dok imate račun kod nas ili nastavljate koristiti naše proizvode).
        </li>
        <li>
          <b>Radnje korisnika</b>: Bilo da vlasnici računa ili njihovi korisnici
          odluče izmijeniti ili izbrisati podatke putem svojih računa.
        </li>
        <li>
          <b>Pravne obveze</b>: zahtijevaju li važeći zakoni da zadržimo
          određene podatke.
        </li>
      </ul>
      <p>
        <h1>6. Vaša prava (u vezi podataka)</h1>
      </p>
      <p>
        <b>Možete imati sljedeća prava u vezi s vašim osobnim podacima:</b>
      </p>
      <ul>
        <li>Pristupiti svojim podacima i zatražiti kopiju.</li>
        <li>
          Zatražiti ispravak ili brisanje netočnih ili nepotrebnih podataka.
        </li>
        <li>Ograničiti ili prigovoriti obradi podataka.</li>
        <li>Povući privolu ako se obrada temelji na privoli.</li>
      </ul>
      <p>
        <h2>Marketinške komunikacije</h2>
      </p>
      <p>
        Ako ne želite primati informacije o proizvodima i uslugama koje nudimo
        mi ili naši partneri, možete odustati od marketinških komunikacija putem
        same komunikacije (npr. putem poveznice u e-poruci) ili tako da nas
        kontaktirate na info@kokolingo.hr. Međutim, nisu sve komunikacije u
        marketinške svrhe. I dalje ćete primati bitne poruke u vezi s vašim
        proizvodima i uslugama, kao što su komunikacije korisničke podrške.
      </p>
      <p>
        <h2>Posebne informacije o europskoj zaštiti podataka</h2>
      </p>
      <p>
        Ako se nalazite u EGP-u, Švicarskoj ili Ujedinjenom Kraljevstvu, imate
        sljedeća prava u vezi s vašim osobnim podacima koje obrađujemo kao
        voditelj obrade:
      </p>
      <ul>
        <li>
          <b>Pravo pristupa i prenosivosti</b>: možete zatražiti pristup osobnim
          podacima koje imamo o vama. U određenim slučajevima također možete
          zatražiti da vam se ovi podaci dostave u formatu koji vam omogućuje
          prijenos na drugog pružatelja usluga.
        </li>
        <li>
          <b>Pravo na brisanje</b>: U određenim okolnostima možete zatražiti
          brisanje svojih osobnih podataka (npr. ako podaci više nisu potrebni
          za svrhe za koje su prikupljeni).
        </li>
        <li>
          <b>Pravo na prigovor na obradu</b>: U određenim situacijama možete od
          nas tražiti da prestanemo obrađivati vaše osobne podatke ili da vam
          prestanemo slati marketinške komunikacije.
        </li>
        <li>
          <b>Pravo na ispravak</b>: imate pravo tražiti da ispravimo sve netočne
          ili nepotpune osobne podatke koje imamo o vama.
        </li>
        <li>
          <b>Pravo na ograničenje obrade</b>: U posebnim okolnostima možete
          zatražiti da ograničimo obradu vaših osobnih podataka (npr. ako
          smatrate da su podaci netočni ili da se obrađuju nezakonito).
        </li>
      </ul>
      <p>Za sve druge regije, na zahtjev i prema važećim zakonima, mi ćemo:</p>
      <ul>
        <li>Pružiti informacije o osobnim podacima koje imamo o vama.</li>
        <li>
          Izmijeniti ili ispraviti vaše osobne podatke, ažurirati vaše postavke
          privatnosti ili vas uputiti na alate za to.
        </li>
        <li>
          Izbrisati vaše osobne podatke ili vas uputiti na alate koji olakšavaju
          njihovo brisanje.
        </li>
      </ul>
      <p>
        <h1>7. Veze trećih strana</h1>
      </p>
      <p>
        Aplikacija može sadržavati poveznice na web stranice ili usluge trećih
        strana. Nismo odgovorni za prakse privatnosti ili sadržaj stranica
        trećih strana. Pregledajte njihova pravila o privatnosti prije nego
        stupite u interakciju s njima.
      </p>
      <p>
        <h1>8. Promjene ove Politike privatnosti</h1>
      </p>
      <p>
        S vremena na vrijeme možemo ažurirati ova pravila privatnosti. Sve
        promjene odrazit će se na ažurirani &quot;Datum stupanja na snagu&quot;
        na vrhu. Potičemo vas da povremeno pregledate ova pravila privatnosti.
      </p>
      <p>
        <h1>9. Kontaktirajte nas</h1>
      </p>
      <p>
        Ako imate bilo kakvih pitanja ili nedoumica u vezi s ovim Pravilima
        privatnosti, obratite nam se na:
      </p>
      <ul>
        <li>Email: info@kokolingo.hr</li>
        <li>Adresa: Ratka Petrovića 54, 51000 Rijeka, Hrvatska</li>
        <li>Kontakt broj: +385 95 369 3919</li>
      </ul>
      <p>Hvala što koristite Kokolingo mobilnu aplikaciju!</p>
    </div>
  </>
);
