// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academy-index-js": () => import("./../../../src/pages/Academy/index.js" /* webpackChunkName: "component---src-pages-academy-index-js" */),
  "component---src-pages-academy-institution-index-js": () => import("./../../../src/pages/AcademyInstitution/index.js" /* webpackChunkName: "component---src-pages-academy-institution-index-js" */),
  "component---src-pages-asha-index-js": () => import("./../../../src/pages/Asha/index.js" /* webpackChunkName: "component---src-pages-asha-index-js" */),
  "component---src-pages-educational-corner-autism-spectrum-disorder-index-js": () => import("./../../../src/pages/EducationalCorner/AutismSpectrumDisorder/index.js" /* webpackChunkName: "component---src-pages-educational-corner-autism-spectrum-disorder-index-js" */),
  "component---src-pages-educational-corner-big-issue-you-havent-heard-about-index-js": () => import("./../../../src/pages/EducationalCorner/BigIssueYouHaventHeardAbout/index.js" /* webpackChunkName: "component---src-pages-educational-corner-big-issue-you-havent-heard-about-index-js" */),
  "component---src-pages-educational-corner-bilingualism-at-children-index-js": () => import("./../../../src/pages/EducationalCorner/BilingualismAtChildren/index.js" /* webpackChunkName: "component---src-pages-educational-corner-bilingualism-at-children-index-js" */),
  "component---src-pages-educational-corner-childhood-apraxia-of-speech-index-js": () => import("./../../../src/pages/EducationalCorner/ChildhoodApraxiaOfSpeech/index.js" /* webpackChunkName: "component---src-pages-educational-corner-childhood-apraxia-of-speech-index-js" */),
  "component---src-pages-educational-corner-children-motivation-for-kokolingo-index-js": () => import("./../../../src/pages/EducationalCorner/ChildrenMotivationForKokolingo/index.js" /* webpackChunkName: "component---src-pages-educational-corner-children-motivation-for-kokolingo-index-js" */),
  "component---src-pages-educational-corner-cognitive-development-of-the-game-index-js": () => import("./../../../src/pages/EducationalCorner/CognitiveDevelopmentOfTheGame/index.js" /* webpackChunkName: "component---src-pages-educational-corner-cognitive-development-of-the-game-index-js" */),
  "component---src-pages-educational-corner-distance-speech-therapy-index-js": () => import("./../../../src/pages/EducationalCorner/DistanceSpeechTherapy/index.js" /* webpackChunkName: "component---src-pages-educational-corner-distance-speech-therapy-index-js" */),
  "component---src-pages-educational-corner-dyslalia-index-js": () => import("./../../../src/pages/EducationalCorner/Dyslalia/index.js" /* webpackChunkName: "component---src-pages-educational-corner-dyslalia-index-js" */),
  "component---src-pages-educational-corner-early-intervention-speech-development-index-js": () => import("./../../../src/pages/EducationalCorner/EarlyInterventionSpeechDevelopment/index.js" /* webpackChunkName: "component---src-pages-educational-corner-early-intervention-speech-development-index-js" */),
  "component---src-pages-educational-corner-enable-great-school-start-index-js": () => import("./../../../src/pages/EducationalCorner/EnableGreatSchoolStart/index.js" /* webpackChunkName: "component---src-pages-educational-corner-enable-great-school-start-index-js" */),
  "component---src-pages-educational-corner-encouraging-correct-pronunciation-index-js": () => import("./../../../src/pages/EducationalCorner/EncouragingCorrectPronunciation/index.js" /* webpackChunkName: "component---src-pages-educational-corner-encouraging-correct-pronunciation-index-js" */),
  "component---src-pages-educational-corner-encouraging-language-understanding-index-js": () => import("./../../../src/pages/EducationalCorner/EncouragingLanguageUnderstanding/index.js" /* webpackChunkName: "component---src-pages-educational-corner-encouraging-language-understanding-index-js" */),
  "component---src-pages-educational-corner-encouraging-speech-expressions-index-js": () => import("./../../../src/pages/EducationalCorner/EncouragingSpeechExpressions/index.js" /* webpackChunkName: "component---src-pages-educational-corner-encouraging-speech-expressions-index-js" */),
  "component---src-pages-educational-corner-environmental-impact-on-speech-index-js": () => import("./../../../src/pages/EducationalCorner/EnvironmentalImpactOnSpeech/index.js" /* webpackChunkName: "component---src-pages-educational-corner-environmental-impact-on-speech-index-js" */),
  "component---src-pages-educational-corner-game-as-a-key-to-childs-growth-index-js": () => import("./../../../src/pages/EducationalCorner/GameAsAKeyToChildsGrowth/index.js" /* webpackChunkName: "component---src-pages-educational-corner-game-as-a-key-to-childs-growth-index-js" */),
  "component---src-pages-educational-corner-how-to-find-time-for-kids-index-js": () => import("./../../../src/pages/EducationalCorner/HowToFindTimeForKids/index.js" /* webpackChunkName: "component---src-pages-educational-corner-how-to-find-time-for-kids-index-js" */),
  "component---src-pages-educational-corner-importance-of-speech-therapy-at-home-index-js": () => import("./../../../src/pages/EducationalCorner/ImportanceOfSpeechTherapyAtHome/index.js" /* webpackChunkName: "component---src-pages-educational-corner-importance-of-speech-therapy-at-home-index-js" */),
  "component---src-pages-educational-corner-index-js": () => import("./../../../src/pages/EducationalCorner/index.js" /* webpackChunkName: "component---src-pages-educational-corner-index-js" */),
  "component---src-pages-educational-corner-kokolingo-for-mobiles-index-js": () => import("./../../../src/pages/EducationalCorner/KokolingoForMobiles/index.js" /* webpackChunkName: "component---src-pages-educational-corner-kokolingo-for-mobiles-index-js" */),
  "component---src-pages-educational-corner-kokolingo-in-osijek-index-js": () => import("./../../../src/pages/EducationalCorner/KokolingoInOsijek/index.js" /* webpackChunkName: "component---src-pages-educational-corner-kokolingo-in-osijek-index-js" */),
  "component---src-pages-educational-corner-language-development-index-js": () => import("./../../../src/pages/EducationalCorner/LanguageDevelopment/index.js" /* webpackChunkName: "component---src-pages-educational-corner-language-development-index-js" */),
  "component---src-pages-educational-corner-liquid-and-non-liquid-speech-index-js": () => import("./../../../src/pages/EducationalCorner/LiquidAndNonLiquidSpeech/index.js" /* webpackChunkName: "component---src-pages-educational-corner-liquid-and-non-liquid-speech-index-js" */),
  "component---src-pages-educational-corner-motor-and-hearing-predictions-index-js": () => import("./../../../src/pages/EducationalCorner/MotorAndHearingPredictions/index.js" /* webpackChunkName: "component---src-pages-educational-corner-motor-and-hearing-predictions-index-js" */),
  "component---src-pages-educational-corner-no-theory-just-praxis-index-js": () => import("./../../../src/pages/EducationalCorner/NoTheoryJustPraxis/index.js" /* webpackChunkName: "component---src-pages-educational-corner-no-theory-just-praxis-index-js" */),
  "component---src-pages-educational-corner-online-speech-therapy-exercises-index-js": () => import("./../../../src/pages/EducationalCorner/OnlineSpeechTherapyExercises/index.js" /* webpackChunkName: "component---src-pages-educational-corner-online-speech-therapy-exercises-index-js" */),
  "component---src-pages-educational-corner-practice-voice-r-index-js": () => import("./../../../src/pages/EducationalCorner/PracticeVoiceR/index.js" /* webpackChunkName: "component---src-pages-educational-corner-practice-voice-r-index-js" */),
  "component---src-pages-educational-corner-practice-voice-s-index-js": () => import("./../../../src/pages/EducationalCorner/PracticeVoiceŠ/index.js" /* webpackChunkName: "component---src-pages-educational-corner-practice-voice-s-index-js" */),
  "component---src-pages-educational-corner-prerequisites-of-reading-and-writing-index-js": () => import("./../../../src/pages/EducationalCorner/PrerequisitesOfReadingAndWriting/index.js" /* webpackChunkName: "component---src-pages-educational-corner-prerequisites-of-reading-and-writing-index-js" */),
  "component---src-pages-educational-corner-preschooler-and-speech-therapy-index-js": () => import("./../../../src/pages/EducationalCorner/PreschoolerAndSpeechTherapy/index.js" /* webpackChunkName: "component---src-pages-educational-corner-preschooler-and-speech-therapy-index-js" */),
  "component---src-pages-educational-corner-reading-and-writing-index-js": () => import("./../../../src/pages/EducationalCorner/ReadingAndWriting/index.js" /* webpackChunkName: "component---src-pages-educational-corner-reading-and-writing-index-js" */),
  "component---src-pages-educational-corner-speech-development-index-js": () => import("./../../../src/pages/EducationalCorner/SpeechDevelopment/index.js" /* webpackChunkName: "component---src-pages-educational-corner-speech-development-index-js" */),
  "component---src-pages-educational-corner-speech-disorder-index-js": () => import("./../../../src/pages/EducationalCorner/SpeechDisorder/index.js" /* webpackChunkName: "component---src-pages-educational-corner-speech-disorder-index-js" */),
  "component---src-pages-educational-corner-speech-disorder-therapy-index-js": () => import("./../../../src/pages/EducationalCorner/SpeechDisorderTherapy/index.js" /* webpackChunkName: "component---src-pages-educational-corner-speech-disorder-therapy-index-js" */),
  "component---src-pages-educational-corner-speech-therapists-and-kokolingo-index-js": () => import("./../../../src/pages/EducationalCorner/SpeechTherapistsAndKokolingo/index.js" /* webpackChunkName: "component---src-pages-educational-corner-speech-therapists-and-kokolingo-index-js" */),
  "component---src-pages-educational-corner-teletherapy-in-early-intervention-index-js": () => import("./../../../src/pages/EducationalCorner/TeletherapyInEarlyIntervention/index.js" /* webpackChunkName: "component---src-pages-educational-corner-teletherapy-in-early-intervention-index-js" */),
  "component---src-pages-educational-corner-voices-k-and-g-index-js": () => import("./../../../src/pages/EducationalCorner/VoicesKAndG/index.js" /* webpackChunkName: "component---src-pages-educational-corner-voices-k-and-g-index-js" */),
  "component---src-pages-faq-page-index-js": () => import("./../../../src/pages/FAQPage/index.js" /* webpackChunkName: "component---src-pages-faq-page-index-js" */),
  "component---src-pages-game-index-js": () => import("./../../../src/pages/Game/index.js" /* webpackChunkName: "component---src-pages-game-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institutions-index-js": () => import("./../../../src/pages/Institutions/index.js" /* webpackChunkName: "component---src-pages-institutions-index-js" */),
  "component---src-pages-kokolingo-gift-index-js": () => import("./../../../src/pages/KokolingoGift/index.js" /* webpackChunkName: "component---src-pages-kokolingo-gift-index-js" */),
  "component---src-pages-media-page-index-js": () => import("./../../../src/pages/MediaPage/index.js" /* webpackChunkName: "component---src-pages-media-page-index-js" */),
  "component---src-pages-online-education-index-js": () => import("./../../../src/pages/OnlineEducation/index.js" /* webpackChunkName: "component---src-pages-online-education-index-js" */),
  "component---src-pages-partners-page-index-js": () => import("./../../../src/pages/PartnersPage/index.js" /* webpackChunkName: "component---src-pages-partners-page-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-mobile-js": () => import("./../../../src/pages/privacy-policy-mobile.js" /* webpackChunkName: "component---src-pages-privacy-policy-mobile-js" */),
  "component---src-pages-programic-index-js": () => import("./../../../src/pages/Programic/index.js" /* webpackChunkName: "component---src-pages-programic-index-js" */),
  "component---src-pages-speech-therapy-assessment-index-js": () => import("./../../../src/pages/SpeechTherapyAssessment/index.js" /* webpackChunkName: "component---src-pages-speech-therapy-assessment-index-js" */),
  "component---src-pages-summer-academy-index-js": () => import("./../../../src/pages/SummerAcademy/index.js" /* webpackChunkName: "component---src-pages-summer-academy-index-js" */),
  "component---src-pages-teletherapy-index-js": () => import("./../../../src/pages/Teletherapy/index.js" /* webpackChunkName: "component---src-pages-teletherapy-index-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-tos-mobile-js": () => import("./../../../src/pages/tos-mobile.js" /* webpackChunkName: "component---src-pages-tos-mobile-js" */)
}

