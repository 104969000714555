import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, ButtonNew } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';
import MaterialPackage from './MaterialPackage';

import './style.scss';

const isEarlyBirdDate = () => {
  const currentDate = new Date();
  const compareTo = new Date('2025-03-01T00:00:00.000Z');
  return currentDate.getTime() > compareTo.getTime();
};

const isPromoDate = () => {
  const currentDate = new Date();
  const compareTo = new Date('2025-03-17T00:00:00.000Z');
  return currentDate.getTime() > compareTo.getTime();
};

const AcademyPricing = () => {
  return (
    <div className="academy-pricing">
      <Row
        className="academy-pricing-section flex-column pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h2" bold center>
            <Localize id="academy.Pricing.Title1" />
          </Text>
          <Text as="h3" center>
            <Localize id="academy.Pricing.Subtitle" />
          </Text>
        </div>
      </Row>
      <Row className="mt-5 academy-material-package-container justify-content-center">
        <Col md={3}>
          <MaterialPackage
            titleKey="academy.Pricing.Details.Title1"
            descriptionKey="academy.Pricing.Details.Description1"
            isLineThrough={isEarlyBirdDate()}
          />
        </Col>
        <Col md={3}>
          <MaterialPackage
            titleKey="academy.Pricing.Details.Title2"
            descriptionKey="academy.Pricing.Details.Description2"
            isLineThrough={isPromoDate()}
          />
        </Col>
        <Col md={3}>
          <MaterialPackage
            titleKey="academy.Pricing.Details.Title3"
            descriptionKey="academy.Pricing.Details.Description3"
          />
        </Col>
      </Row>
      <Row
        className="mt-5 academy-action flex-column pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div>
          <Text as="h4" center>
            <Localize id="academy.Pricing.Explanation.Label" />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.ACADEMY_FORM_URL}>
            <Localize id="academy.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
    </div>
  );
};

export default memo(AcademyPricing);
