import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import { Text, Card, Icon, ButtonNew, PricingItems } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';

import programic3 from '../../../images/programic_3.png';

import './style.scss';

const ProgramicAdditionalInfo = () => {
  return (
    <div>
      <Row
        className="mt-5 mb-5 align-items-center justify-content-center"
        noGutters
      >
        <Col md={{ offset: 0, span: 6 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="programic.Pricing.Section1.Title" />
          </Text>
          <Text>
            <Localize id="programic.Pricing.Section1.Description.First" />
          </Text>
          <Text as="h3" bold className="mt-3 mb-3">
            <Localize id="programic.Pricing.Section2.Title" />
          </Text>
          <Text>
            <Localize id="programic.Pricing.Section2.Description" />
          </Text>
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={programic3} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
      <Row
        className="programic-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h2" bold center>
            <Localize id="programic.Details.Action2.Title" />
          </Text>

          <div className="details-section text-center">
            <Text as="h3" bold>
              <Localize id="programic.Details.Action3.First.Price" />
            </Text>
            <Text as="h3" bold>
              <Localize id="programic.Details.Action3.First.Dates" />
            </Text>
            <Text as="h3">
              <Localize id="programic.Details.Action3.First.Materials" />
            </Text>
            <Text as="h3">
              <Localize id="programic.Details.Action3.First.Recording" />
            </Text>
          </div>

          <Text as="h6" center>
            <Localize
              id="programic.Details.Action3.Second" 
            />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.PROGRAMIC_FORM_URL}>
            <Localize id="programic.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
    </div>
  );
};

export default memo(ProgramicAdditionalInfo);
