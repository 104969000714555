import React from 'react';

export default () => (
  <div className="container">
    <p>Datum stupanja na snagu: 28.1.2025.</p>
    <p>
      Dobrodošli u mobilnu aplikaciju Kokolingo (&quot;Aplikacija&quot;).
      Pristupom ili korištenjem aplikacije, suglasni ste biti vezani ovim
      Uvjetima pružanja usluge (&quot;Uvjeti&quot;). Ako se ne slažete s ovim
      Uvjetima, ne možete koristiti aplikaciju. Ovi Uvjeti predstavljaju pravni
      ugovor između vas (&quot;korisnik&quot;) i Kokolingo (&quot;mi&quot;,
      &quot;nas&quot; ili &quot;naš&quot;).
    </p>
    <h1>1. Korištenje aplikacije</h1>
    <h2>1.1 Ispunjavanje uvjeta</h2>
    <p>
      Samo punoljetne osobe smiju kreirati račun. Djeca mogu pristupiti uslugama
      samo putem računa odrasle osobe ili pod nadzorom skrbnika.
    </p>
    <h2>1.2 Registracija računa</h2>
    <p>
      Za korištenje određenih značajki aplikacije, trebate stvoriti korisnički
      račun. Pristajete na sljedeće:
    </p>
    <ul>
      <li>Da prilikom registracije navedete točne i potpune podatke</li>
      <li>Održavate sigurnost vjerodajnica svog korisničkog računa</li>
      <li>
        Odmah nas obavijestite o svakom neovlaštenom pristupu ili kršenju
        sigurnosti
      </li>
      <li>
        Nećete prodavati, prenositi ili dodjeljivati svoj korisnički račun ili
        bilo koja prava na korisnički račun
      </li>
    </ul>
    <p>
      Svaki korisnički račun mora imati određenog odraslog korisnika koji ima
      najmanje 18 godina i zakonski je kvalificiran za sklapanje ugovora s nama
      prema važećem zakonu. Vi ste odgovorni za čuvanje povjerljivosti svoje
      lozinke i podataka o računu. Ako otkrijemo da je korisnik koji ne
      ispunjava uvjete stvorio korisnički račun, možemo provjeriti podobnost
      korisnika i/ili deaktivirati račun.
    </p>
    <h2>1.3 Dopuštene uporabe</h2>
    <p>
      Suglasni ste koristiti aplikaciju samo za namjeravanu svrhu i u skladu sa
      svim važećim zakonima i propisima. Neovlašteno korištenje, uključujući ali
      ne ograničavajući se na hakiranje, rudarenje podataka ili bilo koje
      korištenje koje krši ove Uvjete, strogo je zabranjeno.
    </p>
    <h1>2. Korisnički sadržaj</h1>
    <h2>2.1 Vlasništvo</h2>
    <p>
      Zadržavate vlasništvo nad svim sadržajem koji izradite ili pošaljete putem
      aplikacije, ako aplikacija pruža takvu mogućnost (&quot;Korisnički
      sadržaj&quot;). Podnošenjem korisničkog sadržaja dajete nam neekskluzivnu,
      besplatnu, svjetsku licencu za korištenje, prikaz i distribuciju sadržaja
      isključivo za rad i poboljšanje aplikacije.
    </p>
    <h2>2.2 Zabranjeni sadržaj</h2>
    <p>
      Naša aplikacija ne nudi opciju generiranja korisničkog sadržaja, ali ako
      se takva opcija pojavi, slažete se da nećete slati sadržaj koji:
    </p>
    <ul>
      <li>Je ilegalan, štetan ili uvredljiv</li>
      <li>Krši prava intelektualnog vlasništva</li>
      <li>Sadrži malware, viruse ili štetan kod</li>
    </ul>
    <p>
      Zadržavamo pravo ukloniti bilo koji korisnički sadržaj koji krši ove
      Uvjete ili je na neki drugi način nepoželjan.
    </p>
    <h1>3. Intelektualno vlasništvo</h1>
    <h2>3.1 Vlasništvo</h2>
    <p>
      Sva prava intelektualnog vlasništva u aplikaciji, uključujući, ali ne
      ograničavajući se na softver, zaštitne znakove i sadržaj, u našem su
      vlasništvu ili su licencirana. Dodijeljena vam je ograničena,
      neisključiva, neprenosiva licenca za korištenje aplikacije isključivo u
      osobne, nekomercijalne svrhe.
    </p>
    <h2>3.2 Ograničenja</h2>
    <p>Ne smijete:</p>
    <ul>
      <li>
        Modificirati, izvršiti obrnuti inženjering ili izraditi izvedenicu
        aplikacije.
      </li>
      <li>
        Koristiti aplikaciju u bilo koju komercijalnu svrhu bez našeg izričitog
        pristanka.
      </li>
    </ul>
    <h1>4. Naknade i plaćanja</h1>
    <h2>4.1 Besplatne i plaćene značajke</h2>
    <p>
      Aplikacija može nuditi i besplatne i plaćene značajke, s naknadama za
      određene značajke koje su objavljene prije kupnje. Posebne značajke usluga
      mogu zahtijevati plaćanje, poput naknade za pretplatu. Sve naknade moraju
      se platiti u skladu s uvjetima plaćanja koji se primjenjuju u trenutku
      dospijeća naknade. Da biste aktivirali i održavali plaćeni račun, morate
      koristiti važeći način plaćanja koji prihvaćamo (npr. kroz Google Play
      Store ili Apple App Store). Registracijom za plaćeni račun, ovlašćujete
      nas da teretimo način plaćanja koji navedete. Osim toga, odgovorni ste za
      sve naknade povezane s proizvodima ili uslugama koje naručite putem
      Usluga. Ako plaćanje nije primljeno putem vašeg načina plaćanja,
      pristajete platiti sve nepodmirene iznose na naš zahtjev i potvrđujete da
      ste isključivo vi odgovorni za rješavanje svih sporova sa svojim
      pružateljem usluga plaćanja.
    </p>
    <h2>4.2. Točnost podataka o plaćanju</h2>
    <p>
      Suglasni ste pružiti potpune i točne podatke za sve kupnje koje se tiču
      naše aplikacije. To uključuje promptno ažuriranje podataka o vašem računu,
      kao što su adresa e-pošte, brojevi kreditnih kartica i datumi isteka, kako
      bi se vaše transakcije mogle obraditi ili kako bismo vas mogli
      kontaktirati kada je to potrebno. Plaćanja u naše ime obrađuje pružatelj
      usluga treće strane. Prihvaćate i slažete se da ako dođe do povrede
      podataka putem procesora plaćanja treće strane, a to nije bilo kakva
      krivnja Tvrtke, Tvrtka neće biti odgovorna za bilo kakve posljedične
      probleme u najvećoj mjeri dopuštenoj primjenjivim zakonom.
    </p>
    <h2>4.3 Povrat novca</h2>
    <p>
      Sva plaćanja su bespovratna, osim ako je propisano zakonom ili je izričito
      navedeno drugačije.
    </p>
    <h2>4.4. Ponavljajuće pretplate</h2>
    <p>
      Kupnjom ponavljajuće pretplate za korištenje Kokolinga, vaša će se
      pretplata automatski nastaviti za odabrano razdoblje i obnavljati za
      sljedeća razdoblja osim ako se ne otkaže. Pristajanjem na ponavljajuću
      pretplatu pristajete, u skladu s važećim zakonom, na automatsko
      obnavljanje vaše pretplate za isto razdoblje odmah nakon završetka
      trenutnog razdoblja. Također nas ovlašćujete da teretimo vaš način
      plaćanja za obnovu osim ako ne otkažete svoj račun prije datuma obnove.
      Teretit ćemo vašu kreditnu karticu (ili način plaćanja koji ste izvorno
      naveli) za svako primjenjivo razdoblje pretplate na temelju termina koji
      ste odabrali, po trenutnoj cijeni. Naplata vaše pretplate počinje nakon
      registracije (kada navedete način plaćanja i platite početnu naknadu za
      pretplatu u određenoj fazi korištenja aplikacije) i ponavlja se na početku
      svakog sljedećeg razdoblja pretplate. Ako bilo koja naknada za pretplatu
      nije plaćena na vrijeme ili ako se vaša uplata ne može obraditi,
      zadržavamo pravo obustaviti, onemogućiti, otkazati ili prekinuti vašu
      pretplatu. Imajte na umu da se datumi naplate mogu malo razlikovati svaki
      mjesec. Vaša pretplata ostat će aktivna dok je ne otkažete ili dok mi ne
      prekinemo vaš pristup ili pretplatu kao što je navedeno u ovim Uvjetima.
    </p>
    <h2>4.5. Promjene naknada ili značajki računa</h2>
    <p>
      Sve naknade objavljene na Uslugama određuje E-Glas d.o.o. prema vlastitom
      nahođenju i možemo povremeno promijeniti naše naknade. Tvrtka E-Glas
      d.o.o. ili njezini partneri će poslati obavijest o nadolazećoj automatskoj
      obnovi prije obnove vaše Ponavljajuće pretplate ako to zahtijeva zakon.
      Možemo promijeniti uvjete pretplate, naknade za pretplatu ili druge
      naknade u bilo kojem trenutku prema vlastitom nahođenju. Ako se cijena
      vaše Ponavljajuće pretplate poveća, obavijestit ćemo vas i pružiti vam
      priliku da promijenite ili otkažete svoju Ponavljajuću pretplatu prije
      primjene tih promjena na vaš račun ili naplate u vezi s automatskim
      obnavljanjem.
    </p>
    <h2>4.6. Otkazivanje Ponavljajuće pretplate</h2>
    <p>
      Kako biste izbjegli naplatu sljedećeg razdoblja Ponavljajuće pretplate,
      morate otkazati svoju Ponavljajuću pretplatu prije završetka trenutnog
      razdoblja pretplate. Za otkazivanje idite na područje Pretplata u
      postavkama aplikacije ili kontaktirajte našu korisničku podršku na
      info@kokolingo.hr. Ako ste svoju Ponavljajuću pretplatu kupili putem treće
      strane, poput trgovine aplikacija, morate otkazati obnovu izravno kod te
      treće strane.
    </p>
    <p>Proces otkazivanja - App Store/iOS</p>
    <ol>
      <li>Otvorite postavke uređaja (Settings)</li>
      <li>Kliknite vaše ime na vrhu</li>
      <li>Odaberite &quot;Pretplate&quot;</li>
      <li>Kliknite na Kokolingo</li>
      <li>Otkažite pretplatu</li>
    </ol>
    <p>Proces otkazivanja - Google Play</p>
    <ol>
      <li>Otvorite Google Play aplikaciju</li>
      <li>U gornjem desnom kutu, odaberite profilnu ikonu</li>
      <li>Kliknite plaćanja i pretplate</li>
      <li>Odaberite pretplate</li>
      <li>Odaberite Kokolingo</li>
      <li>Kliknite &quot;Otkažite pretplatu&quot;</li>
    </ol>
    <p>
      Kako biste izbjegli buduće troškove, otkažite prije datuma obnove. Možete
      otkazati putem aplikacije ili kontaktirati našu korisničku podršku za
      pomoć. Ako ste se pretplatili putem trgovine aplikacija ili treće strane,
      otkažite izravno kod njih. Suglasni ste da nećete kriviti nas i naše
      partnere za bilo kakve neovlaštene troškove izvršene putem vašeg računa
      koje nismo mogli kontrolirati. Međutim, ako prijavite te troškove u roku
      od 30 dana, možemo ih ispitati i eventualno ispraviti.
    </p>
    <h1>5. Odricanje od jamstava</h1>
    <p>
      Aplikacija se daje &quot;kakva jest&quot; i &quot;kako je dostupna&quot;,
      bez bilo kakvih jamstava, izričitih ili podrazumijevanih. Ne jamčimo da će
      aplikacija biti bez grešaka, sigurna ili neprekinuta. Aplikaciju koristite
      na vlastitu odgovornost.
    </p>
    <h1>6. Vlasništvo i licenca za usluge</h1>
    <p>
      Usluge, uključujući sve specifične značajke i funkcionalnosti naših
      proizvoda i usluga (prošle, sadašnje i buduće verzije), u našem su
      vlasništvu i pod našom kontrolom. Sav sadržaj povezan s uslugama (kao što
      je definirano u nastavku) zaštićen je u najvećoj mjeri važećim zakonima o
      autorskim pravima, zaštitnim znakovima, trgovačkom izgledu, patentima i
      drugim zakonima o intelektualnom vlasništvu u Europskoj uniji i
      međunarodno. Pod uvjetom da se strogo pridržavate ovih Uvjeta i plaćate
      sve primjenjive pretplate ili druge naknade, dajemo vam ograničenu,
      neisključivu, opozivu i neprenosivu licencu za pristup, prikaz, pregled i
      korištenje na mobilnom uređaju s omogućenim internetom isključivo za vašu
      osobnu, nekomercijalnu upotrebu. Ova Licenca vam ne daje vlasništvo niti
      bilo kakva prava intelektualnog vlasništva nad Sadržajem, Uslugama ili
      bilo kojim posebnim značajkama naših proizvoda ili Usluga. Ne smijete
      koristiti sadržaj ili usluge u bilo koju drugu svrhu bez našeg izričitog
      prethodnog pismenog dopuštenja.
    </p>
    <h1>7. Pravo na odustanak</h1>
    <p>
      Ako živite u EGP-u, Ujedinjenom Kraljevstvu ili Švicarskoj, prihvaćate i
      slažete se da Tvrtka pruža trenutni pristup određenim Uslugama nakon
      otvaranja računa ili plaćanja naknade za pretplatu. Kao rezultat toga,
      suglasni ste da se odričete prava na povlačenje iz ovih Uvjeta ili
      traženje povrata u roku od četrnaest (14) dana (ili bilo kojeg drugog
      razdoblja koje zahtijevaju lokalni zakoni) nakon otvaranja računa ili
      plaćanja naknade.
    </p>
    <h1>8. Linkovi trećih strana</h1>
    <p>
      Neki dijelovi naših usluga mogu uključivati materijale trećih strana. Osim
      toga, veze na web stranice trećih strana na ovoj stranici mogu voditi do
      stranica koje nisu povezane s nama. Ne pregledavamo niti jamčimo sadržaj
      ili točnost ovih materijala ili web stranica trećih strana i ne preuzimamo
      nikakvu odgovornost za njih, uključujući proizvode, usluge ili druge
      materijale koje oni mogu ponuditi. Potičemo vas da pažljivo pregledate
      politike i prakse trećih strana i osigurate da ih razumijete prije nego
      što se uključite u bilo kakve transakcije. Sve pritužbe, tvrdnje,
      nedoumice ili pitanja u vezi s proizvodima ili uslugama treće strane
      trebaju se uputiti odgovarajućoj trećoj strani.
    </p>
    <h1>9. Ograničenje odgovornosti</h1>
    <p>
      U najvećoj mjeri dopuštenoj zakonom, nismo odgovorni za bilo kakvu
      neizravnu, slučajnu, posljedičnu ili kaznenu štetu koja proizlazi iz vaše
      upotrebe aplikacije, čak i ako smo bili obaviješteni o mogućnosti takve
      štete. Naša ukupna odgovornost za sve zahtjeve povezane s aplikacijom
      ograničena je na iznos koji ste platili, ako ste platili, za korištenje
      aplikacije.
    </p>
    <h1>10. Raskid</h1>
    <p>
      Zadržavamo pravo obustaviti ili ukinuti vaš pristup aplikaciji u bilo koje
      vrijeme i iz bilo kojeg razloga, uključujući kršenje ovih Uvjeta. Nakon
      raskida, sva prava koja su vam dodijeljena ovim Uvjetima odmah prestaju
      važiti. Ako su bilo koje informacije koje pružite lažne, netočne ili krše
      ove Uvjete - ili ako imamo opravdane razloge vjerovati u to - možemo
      obustaviti ili ukinuti vaš Račun ili vam ograničiti pristup nekim ili svim
      Uslugama. Obustava ili prekid vašeg pristupa ne oslobađa vas bilo kakvih
      obveza prema nama, uključujući obveze plaćanja. Povrati za izvršena
      plaćanja bit će osigurani samo u skladu s primjenjivim zakonom.
    </p>
    <h1>11. Promjene Uvjeta</h1>
    <p>
      S vremena na vrijeme možemo ažurirati ove Uvjete. Promjene će stupiti na
      snagu nakon objave ažurirane verzije u aplikaciji. Vaše daljnje korištenje
      aplikacije nakon objave promjena predstavlja prihvaćanje ažuriranih
      Uvjeta.
    </p>
    <h1>12. Mjerodavno pravo</h1>
    <p>
      Ovi Uvjeti regulirani su zakonima Republike Hrvatske. Svi sporovi
      proizašli iz ovih Uvjeta rješavat će se na sudovima Republike Hrvatske.
    </p>
    <h1>13. Privatnost</h1>
    <p>
      Da biste saznali kako prikupljamo, koristimo i dijelimo vaše podatke,
      pogledajte našu{' '}
      <a
        href="http://www.kokolingo.hr/mobilna-aplikacija/izjava-o-privatnosti"
        hrefLang="hr"
      >
        Politiku privatnosti
      </a>
      .
    </p>
    <h1>14. Kontaktirajte nas</h1>
    <p>Ako imate pitanja o ovim Uvjetima, kontaktirajte nas na:</p>
    <p>E-pošta: info@kokolingo.hr</p>
    <p>Adresa: Ratka Petrovića 54, 51000 Rijeka</p>
    <p>Telefon: +385 95 369 3919</p>
    <p>Hvala što koristite Kokolingo mobilnu aplikaciju!</p>
  </div>
);
