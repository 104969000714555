import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import cn from 'classnames';
import { Localize } from '@kokolingo/localisation';
import { Icon } from '../../../components';

import * as images from '../../../images';

const Icons = images.icons;

const MaterialPackage = ({
  titleKey,
  descriptionKey,
  iconName,
  isLineThrough,
}) => {
  const mainContainerClassName = cn({
    'pricing-container': true,
    'teletherapy-pricing-container': true,
    'academy-material-line-through': !!isLineThrough,
  });

  const smartContainerClassName = cn({
    'pricing-main': true,
    'academy-material-main': true,
  });

  return (
    <div className={mainContainerClassName}>
      <div className={smartContainerClassName}>
        {iconName && <Icon name={iconName} variant="ghost" />}
        <div className="academy-material-package-title">
          <b>
            <Localize id={titleKey} />
          </b>
        </div>
      </div>
      <div className="academy-material-package-body pricing-benefits pricing-text">
        <p>
          <Localize id={descriptionKey} />
        </p>
      </div>
    </div>
  );
};

MaterialPackage.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string.isRequired,
  iconName: PropTypes.oneOf(Object.keys(Icons)),
  isLineThrough: PropTypes.bool,
};

MaterialPackage.defaultProps = {
  iconName: null,
  isLineThrough: false,
};

export default memo(MaterialPackage);
