import React from 'react';

import Text from '../../components/Text';
import Icon from '../../components/Icon';

export default () => {
  return (
    <>
      <Text as="h4" color="light" bold>
        Društveni mediji
      </Text>
      <div className="footer-new-socials">
        <a
          href="https://www.facebook.com/kokolingo.hr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="fb" variant="secondary" size="small" />
        </a>
        <a
          href="https://www.instagram.com/kokolingo.hr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="ig" variant="secondary" size="small" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCDqmRYvwh4ijNiPtHhXXmVQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="yt" variant="secondary" size="small" />
        </a>
        <a
          href="https://www.tiktok.com/@koko_lingo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="tt" variant="secondary" size="small" />
        </a>
      </div>
    </>
  );
};
