const paths = require('./paths');

paths.PRICING_ANCHOR = '#cijene-za-roditelje';
paths.FACILITIES_ANCHOR = '#cijene-za-ustanove';
paths.PRICING_ANCHOR = '#cjenik';
paths.COUPONS_FORM_ANCHOR = 'poklonite-kokolingo/#forma';
paths.PRIVACY_POLICY = 'izjava-o-privatnosti';
paths.PRIVACY_POLICY_MOBILE = 'mobilna-aplikacija/izjava-o-privatnosti';
paths.TOS = 'uvjeti-kupnje';
paths.TOS_MOBILE = 'mobilna-aplikacija/uvjeti-kupnje';
paths.ONLINE_EDUCATION = 'online-edukacija';
paths.KOKOLINGO_GIFT = 'poklonite-kokolingo';
paths.FAQ = 'ucestala-pitanja';
// paths.SPEECH_THERAPY_ASSESSMENT = 'logopedska-procjena';
paths.TELETHERAPY = 'kokolingo-ekspert';
paths.ACADEMY = 'akademija-za-predskolarce';
paths.ACADEMY_INSTITUTION = 'akademija-za-ustanove';
paths.SUMMER_ACADEMY = 'ljetna-akademija';
paths.INSTITUTIONS = 'ustanove';

paths.PROGRAMIC= 'programic';

paths.EDUCATIONAL_CORNER = 'edukativni-kutak';
paths.AUTISM_SPECTRUM_DISORDER =
  'edukativni-kutak/poremecaj-iz-spektra-autizma';
paths.BIG_ISSUE_YOU_HAVENT_HEARD_ABOUT =
  'edukativni-kutak/veliki-problem-za-koji-sigurno-niste-culi';
paths.BILINGUALISM_AT_CHILDREN = 'edukativni-kutak/dvojezicnost-djece';
paths.CHILDHOOD_APRAXIA_OF_SPEECH = 'edukativni-kutak/djecja-govorna-apraksija';
paths.CHILDREN_MOTIVATION_FOR_KOKOLINGO =
  'edukativni-kutak/kako-motivirat-djecu-da-ustraju-u-logopedskim-vjezbama';
paths.COGNITIVE_DEVELOPMENT_OF_THE_GAME =
  'edukativni-kutak/spoznajni-razvoj-igre';
paths.DISTANCE_SPEECH_THERAPY =
  'edukativni-kutak/logopedska-terapija-na-daljinu';
paths.EARLY_INTERVENTION_SPEECH_DEVELOPMENT =
  'edukativni-kutak/rana-intervencija-i-jezicno-govorni-razvoj';
paths.ENABLE_GREAT_SCHOOL_START =
  'edukativni-kutak/omogucite-svome-djetetu-dobar-start-u-skoli';
paths.ENCOURAGING_CORRECT_PRONUNCIATION =
  'edukativni-kutak/poticanje-pravilnog-izgovora-rijeci-i-glasova';
paths.ENCOURAGING_LANGUAGE_UNDERSTANDING =
  'edukativni-kutak/poticanje-jezicnog-razumijevanja-i-izrazavanja';
paths.ENCOURAGING_SPEECH_EXPRESSIONS =
  'edukativni-kutak/poticanje-jezicno-govornog-izrazavanja-u-obiteljskom-okruzju';
paths.ENVIRONMENTAL_IMPACT_ON_SPEECH =
  'edukativni-kutak/utjecaj-okoline-na-jezicno-govorni-razvoj-djeteta';
paths.GAME_AS_A_KEY_TO_CHILDS_GROWTH =
  'edukativni-kutak/igra-kljuc-rasta-i-razvoja-djece';
paths.HOW_TO_FIND_TIME_FOR_KIDS =
  'edukativni-kutak/kako-pronaci-vremena-za-vjezbanje-s-djecom';
paths.IMPORTANCE_OF_SPEECH_THERAPY_AT_HOME =
  'edukativni-kutak/vaznost-provodenja-logopedskih-vjezbi-kod-kuce';
paths.KOKOLINGO_FOR_MOBILES =
  'edukativni-kutak/kako-je-nastao-kokolingo-za-mobitele';
paths.KOKOLINGO_IN_OSIJEK = 'edukativni-kutak/kokolingo-u-osijeku';
paths.LANGUAGE_DEVELOPMENT = 'edukativni-kutak/razvoj-jezika-i-govora';
paths.LIQUID_AND_NON_LIQUID_SPEECH =
  'edukativni-kutak/mucanje-i-razvojne-netecnosti-u-govoru';
paths.MOTOR_AND_HEARING_PREDICTIONS =
  'edukativni-kutak/motoricke-i-slusne-predvjezbe';
paths.NO_THEORY_JUST_PRAXIS =
  'edukativni-kutak/ovoga-puta-bez-teorije-samo-praksa';
paths.ONLINE_SPEECH_THERAPY_EXERCISES =
  'edukativni-kutak/online-logopedske-vjezbe';
paths.PRACTICE_VOICE_R = 'edukativni-kutak/kako-uvjezbati-glas-r';
paths.PRACTICE_VOICE_Š = 'edukativni-kutak/kokolingo-i-glas-š';
paths.PREREQUISITES_OF_READING_AND_WRITING =
  'edukativni-kutak/predvjestine-citanja-i-pisanja';
paths.READING_AND_WRITING = 'edukativni-kutak/citanje-i-pisanje';
paths.SPEECH_DEVELOPMENT = 'edukativni-kutak/razvoj-glasova';
paths.SPEECH_DISORDER = 'edukativni-kutak/artikulacijski-i-fonoloski-poremecaj';
paths.SPEECH_DISORDER_THERAPY = 'edukativni-kutak/logopedska-terapija';
paths.SPEECH_THERAPISTS_AND_KOKOLINGO =
  'edukativni-kutak/zasto-logopedi-vole-kokolingo';
paths.TELETHERAPY_IN_EARLY_INTERVENTION =
  'edukativni-kutak/teleterapija-kod-rane-intervencije';
paths.VOICES_K_AND_G = 'edukativni-kutak/izgovor-i-terapija-glasova-k-i-g';
paths.DYSLALIA = 'edukativni-kutak/dislalija';

module.exports = paths;
