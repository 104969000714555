import React, { memo } from 'react';
import { Col, Row, ButtonGroup } from 'react-bootstrap';
import { Localize } from '@kokolingo/localisation';

import programic1 from '../../../images/programic_1.png';
import programic2 from '../../../images/programic_2.png';
import programicVideoThumbnail from '../../../images/programic_video_thumbnail.png';

import { Text, ButtonNew, YouTubeVideo } from '../../../components';
import appUrls from '../../../constants/appUrls';
import emoji from '../../../constants/emoji';
import routes from '../../../routes';

import './style.scss';

const ProgramicIntro = () => {
  return (
    <div className="programic-intro">

      <div className="programic-why-choose mt-5 mb-5">
        <Row noGutters>
          <Col xs={10}>
            <Text as="h2" center bold>
              <Localize id="programic.SectionIntro.Title2" />
            </Text>
            <Row>
              <Text>
                <Localize id="programic.SectionIntro.Description2.First" />
              </Text>
            </Row>
            <Row>
              <Text>
                <Localize id="programic.SectionIntro.Description2.Second" />
              </Text>
            </Row>
            <Row>
              <Text>
                <Localize id="programic.SectionIntro.Description2.Third" />
              </Text>
            </Row>            
          </Col>
        </Row>
      </div>

      <Row
        className="programic-action flex-column mt-5 pl-3 pr-3 justify-content-center align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h3" bold center>
            <Localize id="programic.SectionIntro.Action" />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.PROGRAMIC_FORM_URL}>
            <Localize id="programic.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>

      <Row className="mt-5 justify-content-center align-items-center" noGutters>
        <Col xs={10}>
          <div className="programic-video">
            <YouTubeVideo
              videoId="7t1WT1OrVrY"
              videoImgUrl={programicVideoThumbnail}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-5 align-items-center justify-content-center" noGutters>
        <Col md={{ offset: 0, span: 5 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-0 mb-3">
            <Localize id="programic.ExplanationSection.Title" />
          </Text>
          <Row>
            <Text>
              <Localize
                id="programic.ExplanationSection.Description"
                values={{
                ul: (list) => <ul>{list}</ul>,
                li: (listElement) => <li>{listElement}</li>,
              }}
              />
            </Text>
          </Row>
          <Row>
            <Text>
              <Localize
                id="programic.ExplanationSection.Subdescription"
              />
            </Text>
          </Row>           
        </Col>
        <Col
          className="hero-height"
          md={{ offset: 1, span: 4 }}
          xs={{ offset: 0, span: 9 }}
        >
          <picture className="hero-circle hero-circle-tertiary">
            <img src={programic1} alt="" className="mt-4 mt-sm-0" />
          </picture>
        </Col>
      </Row>
      <Row
        className="programic-action flex-column mt-5 pl-3 pr-3 justify-content-bottom align-items-center"
        noGutters
      >
        <div className="mt-5">
          <Text as="h3" bold center>
            <Localize id="programic.SectionIntro.Action2.First" />
          </Text>
          <Text as="h3" bold center>
            <Localize id="programic.SectionIntro.Action2.Second" />
          </Text>
        </div>
        <div className="mt-3 mb-5">
          <ButtonNew variant="academy" onClick={appUrls.PROGRAMIC_FORM_URL}>
            <Localize id="programic.SectionHeader.CTA" /> {emoji.POINTING_FINGER}
          </ButtonNew>
        </div>
      </Row>
      <Row
        className="mt-5 mb-5 justify-content-center align-items-center"
        noGutters
      >
        <Col md={{ offset: 0, span: 5 }}>
          <picture className="hero-circle d-none d-md-block">
            <img src={programic2} alt="" />
          </picture>
        </Col>
        <Col md={{ offset: 0, span: 5 }} xs={{ offset: 0, span: 10 }}>
          <Text as="h3" bold className="mt-4 mt-md-0 mb-3">
            <Localize id="programic.SectionIntro.Title3" />
          </Text>
          <Text>
            <Localize
              id="programic.SectionIntro.Description3"
              values={{
                ul: (list) => <ul>{list}</ul>,
                li: (listElement) => <li>{listElement}</li>,
              }}
            />
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ProgramicIntro);
