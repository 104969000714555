import React, { memo, useContext } from 'react';
import LazyLoad from 'react-lazyload';
import { PropTypes } from 'prop-types';
import cn from 'classnames';
import { Localize } from '@kokolingo/localisation';

import { Button, ModalContext, PricingItems } from '../../components';
import activityTypesItems from '../../constants/activityTypesItems';
import additionalBenefitsItems from '../../constants/additionalBenefitsItems';
import modalTypes from '../../constants/modalTypes';

const PricingPackage = ({ parrotImage, packageLabel }) => {
  const { setModalData } = useContext(ModalContext);

  const smartContainerClassName = cn({
    'pricing-main': packageLabel === 'Smart',
  });

  const pricingTitleClassName = cn('pricing-title', {
    'pricing-title-bg': packageLabel !== 'Smart',
    smart: packageLabel === 'Smart',
  });

  const pricingGridContainerClassName = cn('pricing-grid-container', {
    smart: packageLabel === 'Smart',
  });

  const pricingButtonClassName = cn('pricing-button', {
    smart: packageLabel === 'Smart',
  });

  return (
    <div className="pricing-container">
      <div className={smartContainerClassName}>
        <h1 className={pricingTitleClassName}>
          <Localize id={`pricing.${packageLabel}`} />
        </h1>
        <LazyLoad offset={100} once>
          <img
            className="pricing-parrot"
            alt="Papiga Koko"
            width="170"
            height="250"
            src={parrotImage}
          />
        </LazyLoad>

        <div className={pricingGridContainerClassName}>
          <div className="subscription-price__value__label">
            <Localize id="pricing.FullPrice" />
          </div>
          <div className="subscription-price__value__label">
            <Localize id="pricing.SpecialOffer" />
          </div>
          <div className="subscription-price__value">
            <p>
              <Localize
                id={`pricing.${packageLabel}PriceFullEuros`}
                values={{
                  span: (priceOld) => (
                    <span className="subscription-price--old">{priceOld}</span>
                  ),
                  space: ' ',
                }}
              />
            </p>
          </div>
          <div className="subscription-price__value text--red">
            <p
              style={{
                position: 'absolute',
                right: '0',
                bottom: '0',
              }}
            >
              <Localize
                id={`pricing.${packageLabel}PriceDiscountEuros`}
                values={{
                  span: (priceNew) => (
                    <span className="subscription-price--new">{priceNew}</span>
                  ),
                  space: ' ',
                }}
              />
            </p>
          </div>
          <div className="subscription-price--bottom">
            <Localize id="pricing.PerMonth" />
          </div>
          <div className="subscription-price--bottom text--red">
            <Localize id="pricing.PerMonth" />
          </div>
        </div>
        <p className="pricing-text pricing-description">
          <Localize id={`pricing.${packageLabel}Description`} />
        </p>
        <Button
          className={pricingButtonClassName}
          onClick={() =>
            setModalData({
              type: modalTypes.REGISTER,
              props: {
                subscriptionKey: packageLabel.toUpperCase(),
                showPackageLabel: true,
              },
            })
          }
        >
          <Localize id="pricing.BuyPackage" />
        </Button>
      </div>
      <div className="pricing-benefits pricing-text">
        <p>
          <Localize id="pricing.IndexMotivation" />
          <b>
            <span style={{ color: '#cc200a' }}>** </span>
            <Localize id={`pricing.${packageLabel}IndexMotivation`} />
          </b>
        </p>
        <p>
          <b>
            <Localize id="pricing.TypesOfActivities" />
          </b>
        </p>
        <PricingItems
          packages={activityTypesItems[packageLabel.toUpperCase()]}
        />
        <p>
          <b>
            <Localize id="pricing.AdditionalBenefits" />
          </b>
        </p>
        <PricingItems
          packages={additionalBenefitsItems[packageLabel.toUpperCase()]}
        />
      </div>
    </div>
  );
};

PricingPackage.propTypes = {
  packageLabel: PropTypes.oneOf(['Premium', 'Smart', 'Mini']).isRequired,
  parrotImage: PropTypes.string.isRequired,
};

export default memo(PricingPackage);
